
#root {
	background-color: #ffffff;
	height: 100vh;
	overflow: auto;
}

.application {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-button {
	align-self: center;
}

.login-element {
	margin: 5px;
	display: flex;
	flex-direction: column;
}

.login-block {
	margin-top: 30px;
	width: 430px;
	display: flex;
	flex-direction: column;
}

.first-card-text-field {
	width: 92%;
}

.card-text-field {
	width: 92%;
}

.card-text-edit {
	background-color: #b7b8b7;
}

.content {
	width: 890px;
	overflow-y: visible;
}

.button-logout {
	position: absolute;
	right: 0;
	margin: 6px;
	flex-flow: column;
	display: flex;
}

.element {
	background-color: #e5e6e5;
	border-radius: 3px;
	display: flex;
	padding: 10px;
	position: relative;
	justify-content: flex-start;
}

.card-control {
	display: flex;
	flex-flow: column;
}

.card-control-row {
	min-width: 10%;
	display: flex;
	flex-flow: row;
	margin-left: 10px;
}

.container {
	margin-top: 5px;
	position: relative;
}

.card-extra-action {
	position: absolute;
	top: 0;
	margin-left: 100%;
	height: 100%;
}

.pinned-card-label {
	position: absolute;
	right: 0;
	top: -3px;
}


.unused_color { color: #ffffff; }
.unused_color { color: #e5e6e5; }
.unused_color { color: #919191; }
.unused_color { color: #b7b8b7; }
.unused_color { color: #d0cccc; }
.unused_color { color: #070707; }

.dark-primary-color    { background: #455A64; }
.default-primary-color { background: #607D8B; }
.light-primary-color   { background: #CFD8DC; }
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #03A9F4; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #757575; }
.divider-color         { border-color: #BDBDBD; }


/*
.App-header {
	margin-top: 20px;
	display: inline-flex;
}

.NewDatum-root {
	border-width: 1px;
	background-color: #202020;
	box-shadow: #B18554;
}

.card-root {
	height: 75pt;
	border-radius: .28571429rem;
	background: #A3BEC8;
	margin: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.data-input {
	padding: 5px;
	background: transparent;
	border: none;
	width: 100%;
	font-size: x-large;
	resize: none;
}
.data-input:focus {
	outline-style: auto;
	outline-color: #fff;
	outline-width: 4px;
}


.card {
	height: 64%;
}

.card-tag-container {
	display: flex;
	margin: 5px;
	min-height: 26px;
}

.card-tag {
	background-color: #74b3ca;
	padding: 4px;
	margin: 1px;
	border-radius: 3px;
}

.card-tag-add {
	background: transparent;
	border: none;
	padding: 0;
}

.data-container {
	height: 63%;
	border: 0;
	margin: 0;
	padding: 0;
}

.data-text {
	font-size: 3em;
	line-height: 2em;
}

.block-status {
	position: absolute;
	right: 0;
}

.block-status-item {
	height: 1.5em;
	width: 1.5em;
	margin: -3px;
	line-height: 1.5em;
	font-size: 1.2em;
	border-radius: 0.75em;
	background-color: white;
	box-shadow: #00000073 2px 2px;
}
*/

.data-dropping {
	border-color: #B18554;
	background-color: #FAB868;
	border-style: dashed;  
	border-radius: inherit;
	height: 100%;
	width: 100%;
	margin: 0px;
	position: absolute;
	overflow: hidden;
	opacity: 0.7;
	pointer-events:none;
}
.data-dropping > img {
	height: 100%;
	padding: 10px;
	opacity: 0.4;
}

.data-container {
	height: 50px;
	width: 50px;
	border-radius: 2px;
	margin-right: 5px;
	overflow: hidden;
}

.data-image {
	height: 100%;
	width: 100%;
}


.card-extra-control-element {
	margin: 5px;
}

.focused-root {
	display: flex;
	flex-flow: column;
	align-items: center;
}

.focused-element {
	width: 1040px;
	height: stretch;
	overflow: scroll;
	outline: none;
}

.focused-input {
	height: 100%;
	margin: 14px;
	width: stretch;
	min-height: stretch;
	resize: none;
	border: none;
	outline: none;
}

.focused-text-height {
	height: stretch !important;
	margin: 20px 45px !important;
	width: stretch !important;
}




.dev-version {
	position: absolute;
	left: 0;
	margin: 3px;
}
.dev-version > * {
	color: #b7b8b7;
	font-family: monospace;
}
